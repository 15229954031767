import { useRef } from 'react';
import { get, cloneDeep } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

const ONBOADING_APP_DATA_PATH = 'onboarding.complete';
const onboardingType = {
  LEGACY_FORM: 'legacyForm',
  GRAPH_FORM: 'graphForm',
};

/**
 * Handlers related to onboarding
 */
export default function useOnboarding(props) {
  const { userProvider, cognitiveCity, exaptiveClient } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const onboardingFormRef = useRef({
    form: null,
    hasLoaded: false,
  });

  return {
    _loadOnboardingForm,
    getOnboardingForm,
    isOnboardingEnabled,
    isOnboardingRequired,
    isOnboardingIncomplete,
    isOnboardingTypeLegacyForm,
    isOnboardingTypeGraphForm,
    redirectToOnboarding,
    onboardingFormSubmitHandler,
    onboardingCompleteHandler,
  };

  async function _loadOnboardingForm() {
    let _onboardingForm = null;

    const { form: formUuid } = cognitiveCity.pages.onboarding;
    const shouldLoadForm =
      isOnboardingEnabled() && isOnboardingTypeLegacyForm() && formUuid;
    if (shouldLoadForm) {
      try {
        const formResp = await exaptiveClient.app.form.getContent({
          uuid: formUuid,
          params: { cityNamespace: 'main' },
        });

        _onboardingForm = formResp.data;
      } catch {
        console.warn('Failed to get onboarding form'); //eslint-disable-line
      }
    }

    onboardingFormRef.current.form = _onboardingForm;
    onboardingFormRef.current.hasLoaded = true;
  }

  function getOnboardingForm() {
    _assertOnboardingFormIsLoaded();

    return onboardingFormRef.current.form;
  }

  function isOnboardingEnabled() {
    return !!cognitiveCity.pages.onboarding.enabled;
  }

  /**
   * @param {Object} user (optional)
   */
  function isOnboardingRequired(user) {
    _assertOnboardingFormIsLoaded();

    if (!isOnboardingEnabled()) {
      return false;
    }

    const onboardingRequired = get(
      cognitiveCity,
      'pages.onboarding.mandatory',
      false
    );
    if (!onboardingRequired) {
      return false;
    }

    const hasPages = _currentFormHasPages();
    return hasPages && !_userHasCompletedSurvey(user);
  }

  /**
   *
   * @param {Object} user (optional)
   */
  function isOnboardingIncomplete(user) {
    _assertOnboardingFormIsLoaded();

    const hasPages = _currentFormHasPages();
    return hasPages && !_userHasCompletedSurvey(user);
  }

  function _assertOnboardingFormIsLoaded() {
    if (!onboardingFormRef.current.hasLoaded) {
      throw new Error('Onboarding form not yet loaded');
    }
  }

  function _currentFormHasPages() {
    let hasPages = false;

    if (isOnboardingTypeLegacyForm()) {
      const _onboardingForm = onboardingFormRef.current.form;
      hasPages = !!get(_onboardingForm, 'pages.length', false);
    } else if (isOnboardingTypeGraphForm()) {
      hasPages = true;
    }

    return hasPages;
  }

  function _userHasCompletedSurvey(user) {
    return userProvider.getUserAppData(ONBOADING_APP_DATA_PATH, user);
  }

  function redirectToOnboarding(from = null) {
    const locationState = !_isOnboardingRoute(location.pathname)
      ? { from: location.pathname }
      : {};
    const _state = location.state ? location.state : locationState;

    navigate('/cognitive/onboarding', {
      state: from ? { from } : _state,
    });
  }

  function isOnboardingTypeLegacyForm() {
    return _getFormType() === onboardingType.LEGACY_FORM;
  }
  function isOnboardingTypeGraphForm() {
    return _getFormType() === onboardingType.GRAPH_FORM;
  }

  function _getFormType() {
    return cognitiveCity.pages.onboarding.type || onboardingType.LEGACY_FORM;
  }

  async function onboardingFormSubmitHandler(submitPayload) {
    if (isOnboardingTypeLegacyForm()) {
      _onboardingLegacyFormSubmitHandler(submitPayload);
    } else if (isOnboardingTypeGraphForm()) {
      _onboardingGraphFormSubmitHandler();
    }
  }

  async function _onboardingLegacyFormSubmitHandler(submitPayload) {
    const usedSubmitPayload = cloneDeep(submitPayload);
    const { properties } = usedSubmitPayload.data;

    // eslint-disable-next-line no-prototype-builtins
    const hasPrivacyProperty = properties.hasOwnProperty('privacy');
    let privacy;

    if (hasPrivacyProperty) {
      privacy = properties.privacy;
      delete properties.privacy;
    }

    await exaptiveClient.app.form.submitForm({
      ...usedSubmitPayload,
      params: { cityNamespace: 'main' },
    });

    if (hasPrivacyProperty) {
      await exaptiveClient.user.setUserPrivacy({
        uuid: userProvider.user.uuid,
        data: { value: privacy },
      });
    }

    await userProvider.setUserAppData(ONBOADING_APP_DATA_PATH, true);
  }

  async function _onboardingGraphFormSubmitHandler() {
    await userProvider.setUserAppData(ONBOADING_APP_DATA_PATH, true);
  }

  async function onboardingCompleteHandler() {
    const redirectPath = get(location, 'state.from');
    if (isOnboardingTypeLegacyForm()) {
      const pathname = redirectPath || '/cognitive';
      navigate(pathname);
    } else if (isOnboardingTypeGraphForm()) {
      // If there's a redirect path stored, use it
      // Otherwise, allow the graphForm onDone handler
      // take care of things
      if (redirectPath) {
        navigate(redirectPath);
      }
    }
  }
}

function _isOnboardingRoute(path) {
  return path.match('/cognitive/onboarding');
}
