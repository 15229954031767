import { useState } from 'react';
import { get, set, cloneDeep } from 'lodash';
import { loadElementWithConnections } from '../../../ElementUtils';

const APPLICATION_USER_DATA_ID = 'cognitiveCity';

/**
 * User data and accessors
 */
export default function useUser({ exaptiveClient, cityInfo, authProvider }) {
  const [user, _setUser] = useState(null);

  return {
    user,
    isCityMember,
    _setUser,
    _getUserData,
    refreshUserData,
    loadUserAppData,
    getUserAppData,
    setUserAppData,
  };

  async function _getUserData() {
    const authUser = (await exaptiveClient.auth.refreshToken()).data;

    if (authUser.isCityMember) {
      const [userElement, appData, cityUserInfo] = await Promise.all([
        _loadUserElement(authUser.uuid),
        loadUserAppData(authUser.uuid),
        _loadUserCityInfo(authUser.uuid),
      ]);
      return {
        ...authUser,
        userElement,
        appData,
        cityUserInfo,
      };
    }

    return authUser;
  }

  function isCityMember() {
    return user?.isCityMember;
  }

  async function _loadUserElement(uuid) {
    const { userType } = cityInfo;
    let userElement;

    try {
      userElement = await loadElementWithConnections({
        exaptiveClient,
        where: { 'aux.user': uuid, type: userType },
      });
    } catch (err) {
      userElement = null;
    }

    return userElement;
  }

  async function _loadUserCityInfo(uuid) {
    let cityUserInfo;

    try {
      const resp = await exaptiveClient.user.read({
        uuid,
        params: { includeCityUserInfo: 'true' },
      });
      cityUserInfo = resp.data.cityUserInfo;
    } catch (err) {
      cityUserInfo = null;
    }

    return cityUserInfo;
  }

  async function loadUserAppData(userUuid = user.uuid) {
    let appData = null;
    try {
      const response = await exaptiveClient.app.userAppData.read({
        user: userUuid,
        city: cityInfo.uuid,
      });
      appData = get(response, `data.${APPLICATION_USER_DATA_ID}`, {});
    } catch (e) {
      appData = {};
    }

    return appData;
  }

  async function refreshUserData() {
    try {
      const _user = await _getUserData();
      _setUser(_user);
    } catch (err) {
      if (err.message === 'timeout') {
        authProvider._setAuthTimeoutError(
          'Retrieving your profile information took longer than normal and has timed out. Please refresh the page'
        );
      }

      throw err;
    }
  }

  function getUserAppData(path, _user = user) {
    return get(_user.appData, path, false);
  }

  async function setUserAppData(path, value = true, _user = user) {
    const appData = cloneDeep(_user.appData);
    set(appData, path, value);

    await exaptiveClient.app.userAppData.upsert({
      city: cityInfo.uuid,
      user: _user.uuid,
      data: {
        [APPLICATION_USER_DATA_ID]: appData,
      },
    });

    refreshUserData();
  }
}
