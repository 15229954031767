import React, { useContext } from 'react';
import UserAuthContext from './UserAuthContext';
import UserAuthProvider from './UserAuthProvider';

function useUserAuth() {
  return useContext(UserAuthContext);
}

function UserAuthConsumer({ children }) {
  return (
    <UserAuthContext.Consumer>
      {context => children(context)}
    </UserAuthContext.Consumer>
  );
}

export {
  UserAuthProvider,
  useUserAuth,
  // for legacy Components
  UserAuthConsumer,
  UserAuthContext,
};

export function createUnauthorizedHandler({ navigate }) {
  return () => {
    // Get the location reference from window to make sure
    // it's not stale.
    const location = window.location;
    if (shouldRedirectToLandingPage(location)) {
      navigate('/cognitive/auth/landing', {
        state: { from: location },
      });
    }
  };
}

function shouldRedirectToLandingPage(location) {
  const exemptionRegexes = [
    /^\/cognitive\/login.*$/,
    /^\/public.*$/,
    /^\/cognitive\/[view|sharedelement].*$/,
  ];
  return !exemptionRegexes.some(regex =>
    regex.test(location.pathname.toLowerCase())
  );
}
