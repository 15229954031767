import { useState } from 'react';

/**
 * Fields related to authentication
 */
export default function useAuth({ exaptiveClient }) {
  const [hasTriedToAuthenticate, _setHasTriedToAuthenticate] = useState(false);
  const [authTimeoutError, _setAuthTimeoutError] = useState(null);
  return {
    hasTriedToAuthenticate,
    _setHasTriedToAuthenticate,
    _deauthenticate,
    authTimeoutError,
    _setAuthTimeoutError,
    isAuthenticated,
  };

  function isAuthenticated() {
    return exaptiveClient.auth.isAuthenticated();
  }

  /**
   * Clears any local auth information. This is useful if the user's token has
   * expired and needs to be cleared so that 'isAuthenticated' will return false.
   * If the user has triggered a deauthentication (aka, logs out), logoutHandler
   * from the useLanding should be used instead.
   * @return {[type]} [description]
   */
  function _deauthenticate() {
    return exaptiveClient.auth.deauthenticate();
  }
}
