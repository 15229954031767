import { useEffect } from 'react';
import { pick } from 'lodash';
import { useCity } from '../../useCity';
import UserAuthContext from '../UserAuthContext';
import useAuth from './useAuth';
import useUser from './useUser';
import useLanding from './useLanding';
import useOnboarding from './useOnboarding';
import { useLocation, useNavigate } from 'react-router';

export default function UserAuthProvider(props) {
  const { render, token } = props;
  const { exaptiveClient, cityInfo, cognitiveCity, cityConfig } = useCity();
  const navigate = useNavigate();
  const location = useLocation();
  const authProvider = useAuth({ exaptiveClient });

  const userProvider = useUser({
    cityInfo,
    exaptiveClient,
    authProvider,
  });

  const onboardingProvider = useOnboarding({
    cognitiveCity,
    exaptiveClient,
    userProvider,
  });

  const landingProvider = useLanding({
    exaptiveClient,
    cityConfig,
    authProvider,
    userProvider,
    onboardingProvider,
  });

  _useInit({
    landingProvider,
    token,
    navigate,
    location,
  });

  const stillAuthenticating =
    !authProvider.hasTriedToAuthenticate &&
    authProvider.isAuthenticated() &&
    !userProvider.user;
  if (stillAuthenticating) {
    return null;
  }

  const authValue = {
    ...pick(authProvider, [
      'hasTriedToAuthenticate',
      'authTimeoutError',
      'isAuthenticated',
    ]),
    ...pick(userProvider, [
      'user',
      'isCityMember',
      'refreshUserData',
      'getUserAppData',
      'loadUserAppData',
      'setUserAppData',
    ]),
    ...landingProvider,
    ...onboardingProvider,
  };
  return (
    <UserAuthContext.Provider value={authValue}>
      {render(authValue)}
    </UserAuthContext.Provider>
  );
}

function _useInit({ landingProvider, token, navigate, location }) {
  useEffect(() => {
    async function tryLogin() {
      if (token) {
        landingProvider.loginWithStoredTokenHandler();
      } else if (shouldRedirectToLandingPage(location)) {
        await fetch('/cognitive/auth/logout', { method: 'post' });
        return navigate('/cognitive/auth/landing');
      }
      return null;
    }
    tryLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

function shouldRedirectToLandingPage(location) {
  const exemptionRegexes = [
    /^\/cognitive\/login.*$/,
    /^\/public.*$/,
    /^\/cognitive\/[view|sharedelement].*$/,
  ];
  return !exemptionRegexes.some(regex =>
    regex.test(location.pathname.toLowerCase())
  );
}
