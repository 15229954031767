import * as freshdesk from '../Freshdesk';

function identify(user) {
  freshdesk.identify(user);
}

function logout(user) {
  freshdesk.logout(user);
}

export { identify, logout };
