/**
 * Docs:
 * https://developers.freshdesk.com/widget-api/
 */

function getFreshdeskWidget() {
  return window.FreshworksWidget;
}

function identify(user) {
  const freshdeskWidget = getFreshdeskWidget();
  if (!freshdeskWidget) {
    return;
  }
  freshdeskWidget('identify', 'ticketForm', {
    name: `${user.firstname || ''} ${user.lastname || ''}`,
    email: user.email,
  });

  // Only disable these fields if we have user info.
  const fieldsToDisable = [
    user.email ? 'email' : null,
    (user.firstname || user.lastName) ? 'name' : null,
  ];
  if (user.email) {
    freshdeskWidget('disable', 'ticketForm', fieldsToDisable);
  }
}

function logout(user) {
  const freshdeskWidget = getFreshdeskWidget();
  if (!freshdeskWidget) {
    return;
  }
  // Turn off and back on to clear the form and re-enable the fields.
  freshdeskWidget('destroy');
  freshdeskWidget('boot');
}

export { identify, logout };
