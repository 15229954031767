import * as analytics from '../../../Analytics';

const EMBEDDED_TOOLS_PATH_REGEX = /^\/cognitive\/tools\/run\/[^/]+$/;

export default function useLanding({
  exaptiveClient,
  authProvider,
  userProvider,
  onboardingProvider,
}) {
  return {
    loginHandler,
    loginWithStoredTokenHandler,
    shouldRedirectToOnboarding,
  };

  async function loginHandler({ user }) {
    await onboardingProvider._loadOnboardingForm();
    userProvider._setUser(user);
    authProvider._setHasTriedToAuthenticate(true);
    analytics.identify(user);
  }

  async function loginWithStoredTokenHandler() {
    if (await _authWithStoredToken()) {
      const user = await userProvider._getUserData();

      if (onboardingProvider.isOnboardingRequired(user)) {
        onboardingProvider.redirectToOnboarding();
      }
    }
  }

  async function _authWithStoredToken() {
    try {
      const user = await userProvider._getUserData();

      // _loadOnboardingForm() must run before userProvider._setUser()
      // or NavHeader will throw "Error: Onboarding form not yet loaded" when the user is set.
      await onboardingProvider._loadOnboardingForm();

      analytics.identify(user);

      userProvider._setUser(user);
      authProvider._setHasTriedToAuthenticate(true);
      return true;
    } catch (err) {
      if (
        err.message === 'Token authentication failed' ||
        err.message === 'Failed to authenticate' ||
        (err.response && err.response.status === 401)
      ) {
        authProvider._deauthenticate();
      } else if (err.message === 'timeout') {
        authProvider._setAuthTimeoutError(
          'Retrieving your profile information took longer than normal and has timed out. Please refresh the page'
        );
      } else {
        // eslint-disable-next-line no-console
        console.error(
          `An unknown error has occured while authenticating via an existing token with message "${err.message}"`,
          err
        );
        authProvider._deauthenticate();
      }
      authProvider._setHasTriedToAuthenticate(true);
      return false;
    }
  }

  function shouldRedirectToOnboarding({ user, from }) {
    const isEnabled = onboardingProvider.isOnboardingEnabled();
    if (!isEnabled) {
      return false;
    }

    const isRequired = onboardingProvider.isOnboardingRequired(user);
    const isIncomplete = onboardingProvider.isOnboardingIncomplete(user);
    const cameFromEmbeddedTool =
      from && from.pathname && from.pathname.match(EMBEDDED_TOOLS_PATH_REGEX);

    if (isRequired || (isIncomplete && !cameFromEmbeddedTool)) {
      return true;
    }

    return false;
  }
}
